import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import CodepenIcon from '../images/codepen.svg'
import GitHubIcon from '../images/github.svg'
import LinkedInIcon from '../images/linkedin.svg'

const Header = styled.header`
  display: flex;
  margin-bottom: 0;
  align-items: space-between;

  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }

  @media (min-width: 1024px) {
    margin-bottom: 2rem;
    align-items: center;
  }
`

const IconContainer = styled.div`
  width: 25px;
  height: 25px;

  @media (min-width: 768px) {
    width: 35px;
    height: 35px;
  }

  @media (min-width: 1024px) {
    width: 50px;
    height: 50px;
  }
`

const SocialMediaList = styled.ul`
  list-style: none;
  display: flex;
  width: 20%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;

  @media (min-width: 1024px) {
    flex-direction: row;
    width: 30%;
  }
`
const SocialMediaLink = styled.li`
  width: 33.333%;
  margin-left: 0;
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
`

const H1 = styled.h1`
  font-family: 'Shadows Into Light', sans-serif;
  color: blue;
  font-size: 3rem;
  width: 80%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 4rem;
  }

  @media (min-width: 1024px) {
    width: 70%;
  }
`

const Span = styled.span`
  display: block;

  @media (min-width: 1024px) {
    display: inline;
  }
`

export default ({ siteTitle }) => (
  <>
    <Header>
      <H1>
        Brian <Span>Washington</Span>
      </H1>
      <SocialMediaList>
        <SocialMediaLink>
          <a
            href="https://www.linkedin.com/in/brian-washington/"
            target="_blank"
          >
            <IconContainer>
              <LinkedInIcon />
            </IconContainer>
          </a>
        </SocialMediaLink>
        <SocialMediaLink>
          <a href="https://github.com/brianwashington" target="_blank">
            <IconContainer>
              <GitHubIcon />
            </IconContainer>
          </a>
        </SocialMediaLink>
        <SocialMediaLink>
          <a href="https://codepen.io/brianwashington" target="_blank">
            <IconContainer>
              <CodepenIcon />
            </IconContainer>
          </a>
        </SocialMediaLink>
      </SocialMediaList>
    </Header>
  </>
)

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

// export default Header
